<template>
  <div class="anno-root">
    
    <el-table height="260" :data="projectArr"  empty-text="No Data" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
        <el-table-column label="Date" width="130" align="center">
            <template slot-scope="scope">
                {{   '2024-08-09' }}
            </template>
        </el-table-column>
        <el-table-column label="PD Line" align="left">
        
            <template slot-scope="scope">
                {{  'PD Line' }}
            </template>
        </el-table-column>      	

        <el-table-column  label="Project" align="center">
            <template slot-scope="scope">
              {{ scope.row.project_code }}
            </template>
        </el-table-column>

        <el-table-column label="Type" align="center">
            <template slot-scope="scope">
              <el-button :class=" scope.row.registration_status == 'none' ? 'btn-linkSelect' : 'btn-link' " @click="toCheck(scope.row,'none')" type="primary" size="small">None</el-button>
              <el-button :class=" scope.row.registration_status == 'sub' ? 'btn-linkSelect' : 'btn-link' " @click="toCheck(scope.row,'sub')" type="primary" size="small">Sub</el-button>
              <el-button :class=" scope.row.registration_status == 'reg' ? 'btn-linkSelect' : 'btn-link' " @click="toCheck(scope.row,'reg')" type="primary" size="small">Reg</el-button>
            </template>
        </el-table-column>


        <el-table-column label="List" width="100" align="center">
            <template slot-scope="scope">
              <div @click="showList(scope.row.project_code)">
                {{' 10 / 20'}}
              </div>
            </template>
        </el-table-column>

        <el-table-column label="Score Line" width="100" align="center">
            <template slot-scope="scope">
              {{' 80'}}
            </template>
        </el-table-column>


        <el-table-column label="Config" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.registration_status == 'sub'" :class=" scope.row.submission_status == 'on' ? 'btn-linkSelect' : 'btn-link' " @click="toSubmission(scope.row,'on')" type="primary" size="small">On</el-button>
              <el-button v-if="scope.row.registration_status == 'sub'" :class=" scope.row.submission_status == 'off' ? 'btn-linkSelect' : 'btn-link' " @click="toSubmission(scope.row,'off')" type="primary" size="small">Off</el-button>

              <el-button v-if="scope.row.registration_status == 'reg'" class="btn-link" @click="toA(scope.row)" type="primary" size="small">FBLA</el-button>
              <el-button v-if="scope.row.registration_status == 'reg'" class="btn-link" @click="toA(scope.row)" type="primary" size="small">BPA</el-button>
              <el-button v-if="scope.row.registration_status == 'reg'" class="btn-link" @click="toA(scope.row)" type="primary" size="small">DECA</el-button>
            </template>
        </el-table-column>

    </el-table>



    <el-drawer
      size="40vw"
      title="Advancement List"
      :visible.sync="showAdvancementList"
      direction="ltr"
      :before-close="handleClose">
      
      <el-table style="height: 80vh;" :data="appList"  empty-text="No Data" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table">
          <el-table-column label="RK"  align="center">
              <template slot-scope="scope">
                  {{   '1' }}
              </template>
          </el-table-column>
          <el-table-column label="Name" align="left">
          
              <template slot-scope="scope">
                  {{  'PDName' }}
              </template>
          </el-table-column>      	

          <el-table-column  label="S1" align="center">
              <template slot-scope="scope">
                {{ '80' }}
              </template>
          </el-table-column>

          <el-table-column  label="S2" align="center">
              <template slot-scope="scope">
                {{ '60' }}
              </template>
          </el-table-column>


          <el-table-column  label="Total" align="center">
              <template slot-scope="scope">
                {{ '140' }}
              </template>
          </el-table-column>

          <el-table-column label="Select" align="center">
              <template slot-scope="scope">
                <el-checkbox v-model="checked" @change="selectApp()"></el-checkbox>
              </template>
          </el-table-column>

      </el-table>

      <div style="text-align: right;margin-right: 30px;margin-top: 10px;">
        <el-button  @click="toSubmitList()" type="primary" size="small">Confirm</el-button>
        <el-button  @click="closeList()" type="primary" size="small">Cancel</el-button>

      </div>
     


    </el-drawer>

  </div>
</template>

<script>
import {getCompleteExamProjects,updateProjectInfo,updateProjectSubmission} from '../../api/index'
import {formatDate} from '../../utils/date'

export default {
  name: "index",
  data() {
    return {
      projectArr: [],
      appList:['2','3','4'],
      showIndex:1,
      showAdvancementList:false
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    toA(row){

    },
    toSubmitList(){

    },
    showList(){
      this.showAdvancementList = true
    },
    closeList(){
      this.showAdvancementList = false
    },
    selectApp(){
      console.log(11111)
    },
    toSubmission(row,type){
      
      updateProjectSubmission(row._id.$id,type).then((res) => {
        this.fetchData()
      })
    },
    toCheck(row,type){
      
      updateProjectInfo(row._id.$id,type).then((res) => {
        this.fetchData()
      })

      console.log(1111,this.projectArr )

    },
    formatCreateTime(create_time) {
      var time = JSON.parse(create_time) * 1000
      var temp_date = new Date(time)
      return formatDate(temp_date, 'yyyy/MM/dd')
    },
    fetchData() {
      getCompleteExamProjects().then((res) => {
        this.projectArr = res.data.data
      })
    }
  }
}
</script>

<style lang="less" scoped>

.anno-list-item-content {
  flex: 1;
  margin-right: 10px;
  font-family: Montserrat-SemiBold;
  color: #072D76;
  font-size: 26px;
  // font-weight: bold;
}
.titleStyle{
  font-family: Montserrat-SemiBold;
  font-size: 16px;

}
.contentStyle{
  margin-top: 10px;
  font-family: Montserrat-SemiBold;
  font-size: 16px;

}
.anno-list-item-date {
  margin-right: 10px;
  font-family: Montserrat-SemiBold;
  color: #072D76;
  font-size: 20px;
  font-weight: bold;
}

.anno-list-item {
  margin-top: 20px;
  width: 100%;
  padding-bottom: 8px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #072D76;
  display: flex;
  flex-direction: row;
}

.anno-root {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.anno-content {
  flex: 1;
  width: 100%;
  background-color: #F3F5F9;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.anno-list {
  padding-left: 40px;
  padding-right: 40px;
}

.btn-linkSelect{
  background-color:  red;
  border-color: red;
}
</style>